.gallery-cell {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-image {
  display: block;
  max-width: 100%;
  height: auto;
}

.dropdown-button-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  color: white;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
}

.dropdown-button-custom:hover,
.dropdown-button-custom:focus {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
