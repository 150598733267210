body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropzone-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed #007bff;
  border-radius: 0;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(233, 236, 239, 0.9);
  z-index: 1000;
}

