.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    padding: 10px;
}

.image-grid img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 5px;
}

.navbar .ml-auto {
    display: flex;
    align-items: center;
}

#userDisplay {
    margin-right: 10px; /* Adjust spacing between username and logout button */
}

.delete-btn {
    background-color: transparent;
    color: white;
    border: none;
    text-shadow: -1px -1px 0 #000,
                 1px -1px 0 #000,
                 -1px 1px 0 #000,
                 1px 1px 0 #000;
}

.delete-btn:focus, .delete-btn:active {
    background-color: red;
    color: white;
    border: none;
    text-shadow: none;
}

.delete-btn:hover {
    background-color: red;
    color: white;
    border: none;
    text-shadow: none;
}

.restore-btn {
    background-color: #20c997;
    color: white;
    border: none;
}

.restore-btn:hover {
    background-color: transparent;
    color: white;
    border: none;
}

/*
  background-color: rgb(244, 241, 240);
  background-color: rgb(153, 153, 153);
  background-color: rgb(156, 175, 136);
 */
.custom-header {
  color: rgb(156, 175, 136);
}

.custom-main-content {
  background-color: rgb(244, 241, 240);
}

.custom-navbar, .custom-footer {
}

.custom-nav-link {
}
